exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adult-room-js": () => import("./../../../src/pages/adult-room.js" /* webpackChunkName: "component---src-pages-adult-room-js" */),
  "component---src-pages-blog-contentful-blog-blog-heading-js": () => import("./../../../src/pages/blog/{ContentfulBlog.blogHeading}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-blog-heading-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-category-contentful-cetegory-category-title-js": () => import("./../../../src/pages/category/{ContentfulCetegory.categoryTitle}.js" /* webpackChunkName: "component---src-pages-category-contentful-cetegory-category-title-js" */),
  "component---src-pages-category-index-js": () => import("./../../../src/pages/category/index.js" /* webpackChunkName: "component---src-pages-category-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-escorts-contentful-vip-profile-girl-name-js": () => import("./../../../src/pages/escorts/{ContentfulVipProfile.girlName}.js" /* webpackChunkName: "component---src-pages-escorts-contentful-vip-profile-girl-name-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-contentful-location-location-title-js": () => import("./../../../src/pages/location/{ContentfulLocation.locationTitle}.js" /* webpackChunkName: "component---src-pages-location-contentful-location-location-title-js" */),
  "component---src-pages-location-index-js": () => import("./../../../src/pages/location/index.js" /* webpackChunkName: "component---src-pages-location-index-js" */),
  "component---src-pages-mumbai-call-girls-js": () => import("./../../../src/pages/mumbai-call-girls.js" /* webpackChunkName: "component---src-pages-mumbai-call-girls-js" */),
  "component---src-pages-mumbai-escorts-js": () => import("./../../../src/pages/mumbai-escorts.js" /* webpackChunkName: "component---src-pages-mumbai-escorts-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-profile-contentful-profile-name-js": () => import("./../../../src/pages/profile/{ContentfulProfile.name}.js" /* webpackChunkName: "component---src-pages-profile-contentful-profile-name-js" */)
}

